import React, { createRef } from "react"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import OptimizedImage from "../../utils/optimizedImage";

import { TestimonialCarouselWrapper } from './styles';

export const TestimonialCarouselNoHeadshot = ({ component }) => {
  const { testimonials, heading, noTopPadding, noBottomPadding } = component;
  const sliderRef = createRef();

  const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: false,
    draggable: true,
    swipeToSlide: true,
    variableWidth: true,
    speed: 1000,
  };

  return (
    <TestimonialCarouselWrapper noTopPadding={noTopPadding} noBottomPadding={noBottomPadding}>
      <div className="testimonial-carousel--container">
        <div className="heading">{heading}</div>
        <div className="testimonial-carousel--main-container">
          <Slider ref={sliderRef} {...sliderSettings}>
            {testimonials?.map((item, index) => (
              <div className="testimonial-carousel--item" key={index}>
                <div className="testimonial-carousel--item-wrapper">
                  <div className="testimonial-carousel--item-text-container">
                    <div className="testimonial-carousel--item-overlay" />
                    <div className="testimonial-carousel--item-quote-container">
                      {item?.logo?.file?.url &&
                        <OptimizedImage className="logo" image={item?.logo?.gatsbyImageData} src={item?.logo?.file?.url} alt={item?.logo?.description || ''} />
                      }
                      {item?.quote?.quote && <p className="testimonial-carousel--item-quote">{item?.quote?.quote}</p>}
                      {item?.author && <p className="testimonial-carousel--item-author"><b>{item?.author?.fullName || ''},</b> {item?.author?.role || ''}</p>}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </TestimonialCarouselWrapper>
  )
}
